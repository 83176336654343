import * as React from 'react'
import { Link } from 'gatsby'

import IndexLayout from '../layouts'

const NotFoundPage = (props: { location: Location }) => (
  <IndexLayout location={props.location}>
    <h1>404: К сожалению, такой страницы не существует</h1>
    <p>Вот случайная гифка с котиком, чтобы вы не расстраивались:</p>
    <img src="https://cataas.com/cat/gif" alt="Random Cat Gif" />

    <p>
      <Link to="/">На главную</Link>
    </p>
  </IndexLayout>
)

export default NotFoundPage
